/* global jQuery */

var $ = jQuery,
    App = {
        debug: true,
        init: function() {

            this.scripts();
            this.sliders();
            this.mobMenu($(".page-header"));

        },
        loaded: function() {

            var $homeLetters = $(".home-letters");

            if ($homeLetters.length) {
                $homeLetters.addClass("loaded");
            }

        },

        scripts: function() {

            if (App.is_touch_device()) {
                $("body").addClass("touch");
            } else {
                $("body").addClass("no-touch");
            }

            var $win = $(window),
                $body = $("body"),
                scrollPos = $win.scrollTop();

            $win.on('scroll', function() {

                if ($(this).scrollTop() >= 50) {
                    $('body').addClass('page-scrolled');
                } else {
                    $('body').removeClass('page-scrolled');
                }

                scrollPos = $win.scrollTop();

            });

            if ($win.scrollTop() >= 50) {
                $('body').addClass('page-scrolled');
            } else {
                $('body').removeClass('page-scrolled');
            }

            var $pageHeader = $('.page-header'),
                $searchTrigger = $(".search-trigger"),
                $searchClose = $(".search-close"),
                $sidebarTrigger = $(".sidebar-trigger"),
                $sidebar = $("ul.sidebar"),
                gallery = $('.gallery');


            function simpleParallax(intensity, element) {
                $win.scroll(function() {
                    var scrollTop = $(window).scrollTop();
                    var elPos = scrollTop / intensity + 'px';
                    element.css('transform', 'translateY(' + elPos + ')');
                });
            }

            var parallaxEl = $('.hero-bg .img-bg');

            simpleParallax(1.5, parallaxEl);

            $searchTrigger.click(function(e) {
                e.preventDefault();
                $pageHeader.addClass("search-opened");
                $(this).parent().addClass("search-opened");
                $(this).parent().find('form input[type=text]').focus();
            });

            $searchClose.click(function(e) {
                e.preventDefault();
                $pageHeader.removeClass("search-opened");
                $(this).parent().parent().removeClass("search-opened");
            });

            if ($win.width() < 768) {
                $sidebar.hide();
            }

            $sidebarTrigger.click(function(e) {
                e.preventDefault();
                $(this).toggleClass("triggered");
                $sidebar.slideToggle(400);
            });


            if (gallery && gallery.length) {
                gallery.lightGallery({
                    thumbnail: true,
                    selector: 'a'
                });
            }

            // To top
            $(".back-top").on("click", function() {
                $("html,body").animate({
                    scrollTop: 0
                }, 550);
            });

            // Content link
            $(".content-link").click(function(e) {
                if ($(this).attr('data-slug').length && (typeof $(this).data('slug') !== 'undefined')) {
                    e.preventDefault();
                    var id = $(this).attr('data-slug');
                    $('html,body').animate({
                        scrollTop: $('#' + id).offset().top - 50
                    }, 550);
                }
            });

        },

        sliders: function() {

            var full_slider = $('.full-slider .swiper-container');

            if (full_slider.length) {

                var full_slider_slider = new Swiper(full_slider, {
                    loop: true,
                    effect: 'slide',
                    centeredSlides: true,
                    paginationClickable: true,
                    paginationHide: true,
                    slidesPerView: 1,
                    spaceBetween: 0,
                    speed: 700,
                    grabCursor: false,
                    //autoplay: 6000,
                    navigation: {
                        nextEl: '.full-slider .swiper-button-next',
                        prevEl: '.full-slider .swiper-button-prev',
                    }
                });

            }

        },

        mobMenu: function(menuWrapper) {

            var $this = menuWrapper,
                $body = $("body");

            /* toggle main menu nav */
            $(".menu-btn", $this).on("click", function() {

                menuWrapper.toggleClass("menu-opened");
                $body.toggleClass("menu-opened");

            });

            $(window).on("keyup", function(event) {

                if ($body.hasClass("menu-opened")) {
                    switch (event.keyCode) {
                        case 27: //esc
                            menuWrapper.removeClass("menu-opened");
                            $body.removeClass("menu-opened");
                            break;
                    }
                }

            });

        },

        log: function(msg) {
            if (this.debug) {
                if (arguments.length > 1) {
                    var to_console = [];
                    for (i = 1; i < arguments.length; i++) {
                        to_console[i - 1] = arguments[i][0];
                    }
                    console.log(msg, to_console);
                } else {
                    console.log(msg);
                }
            }
        },
        is_touch_device: function() {
            return (("ontouchstart" in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
        }

    };

jQuery(document).ready(function() {
    App.init();
});
jQuery(window).on("load", function() {
    App.loaded();
});